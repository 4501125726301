import Vue from 'vue';
import VueRouter from 'vue-router';
import authService from '@/service/auth.service';
import analyticsService from '@/service/analytics.service';
import autoRefreshService from '@/service/auto-refresh.service';
import userProfileService from '@/service/user-profile.service';
import { getVendorForUser } from '@/service/vendors.service';
import esApiV3 from '@/service/esapiv3.service';
import { getSignedUrl } from '@/plugins/s3';
import { useAssetStore } from '@/stores/assetStore';
import { useLocationStore } from '@/stores/locationStore';
import { useTaskStore } from '@/stores/taskStore';
import { useAuthStore } from '@/stores/authStore';
import { useCustomerStore } from '@/stores/customerStore';
import { useRequestStore } from '@/stores/requestStore';
import { useGlobalStore } from '@/stores/globalStore';

const LoginLayout = () => import('@/views/Login.vue');
const LogoutLayout = () => import('@/views/PTLogout.vue');
const PortalLayout = () => import('@/views/PortalLayout.vue');
const AtAGlance = () => import('@/views/AtAGlance.vue');
const RequestsView = () => import('@/views/Requests.vue');
const RequestPage = () =>
  import('@/components/request-list/request-list-page.vue');
const RequestCreateButton = () =>
  import('@/components/request-list/requests-create-button.vue');
const AssetsView = () => import('@/views/Assets.vue');
const AssetDetail = () => import('@/views/AssetDetail.vue');
const RequestDetail = () => import('@/views/RequestDetail.vue');
const VendorProfile = () => import('@/views/VendorProfile.vue');
const ReportingView = () => import('@/views/ReportingLayout.vue');
const RequestReporting = () => import('@/views/RequestReporting.vue');
const InvoiceReportingView = () => import('@/views/InvoiceReportingView.vue');
const InvoiceReporting = () => import('@/views/InvoiceReporting.vue');
const UnmatchedInvoices = () => import('@/views/UnmatchedInvoices.vue');
const InsightsReporting = () => import('@/views/InsightsReporting.vue');
const VendorInsightsReporting = () =>
  import('@/views/vendor-reporting-insights/VendorInsightsReporting.vue');
const PMInsightsReporting = () => import('@/views/PMInsightsReporting.vue');
const ProfileView = () => import('@/views/Profile.vue');
const LinkAccounts = () => import('@/views/LinkAccounts.vue');
const NoPermissions = () => import('@/views/NoPermissions.vue');
const AdminView = () => import('@/views/Admin.vue');
const CsatView = () => import('@/views/Csat.vue');
const EepManufacturerCsatView = () => import('@/views/EepManufacturerCsat.vue');
const VendorDispatch = () => import('@/views/VendorDispatch.vue');
const SSOVendorDispatch = () => import('@/views/SSOVendorDispatch.vue');
const VendorRequestQuote = () => import('@/views/VendorRequestQuote.vue');
const MaintenanceView = () => import('@/views/Maintenance.vue');
const PageNotFound = () => import('@/views/PageNotFound.vue');
const TasksLayout = () => import('@/views/TasksLayout.vue');
const TaskDetailLayout = () => import('@/views/TaskDetailLayout.vue');
const TaskDetail = () => import('@/views/TaskDetail.vue');
const VendorFollowUp = () => import('@/views/VendorFollowUp.vue');
const RequestIntakeMagicLink = () =>
  import('@/views/RequestIntakeMagicLink.vue');
const InvalidLinkView = () => import('@/views/InvalidLink.vue');
const ErrorView = () => import('@/views/ErrorPage.vue');
const AppDownloadView = () => import('@/views/AppDownload.vue');
const SelfServiceVendorFollowUp = () =>
  import(
    '@/components/requests/self-service/self-service-vendor-follow-up.vue'
  );
const RequestTroubleshootingGuidesList = () =>
  import('@/views/RequestTroubleshootingGuidesList.vue');
const RequestTroubleshootingGuide = () =>
  import('@/views/RequestTroubleshootingGuideView.vue');
const VendorsView = () => import('@/views/Vendors.vue');
const VendorNetworkCard = () =>
  import('@/components/vendors/tabs/vendor-network-tab.vue');
const VendorHistoryCard = () =>
  import('@/components/vendors/tabs/vendor-history-tab.vue');
const VendorEightysixNetworkCard = () =>
  import('@/components/vendors/tabs/vendor-es-network-tab.vue');
const EscalationsLayout = () => import('@/views/EscalationsLayout.vue');
const EscalationDetail = () => import('@/views/EscalationDetail.vue');
const EscalationCreateButton = () =>
  import('@/components/escalation-list/escalation-create-button.vue');

const ALLOW_AUTH_SKIP = [
  'Csat',
  'EepManufacturerCsat',
  'VendorDispatch',
  'SSOVendorDispatch',
  'VendorRequestQuote',
  'VendorFollowUp',
  'RequestIntakeMagicLink',
  'InvalidLink',
  'AppDownloadView',
  'AssetQRCode',
  'Error',
];

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    component: LoginLayout,
    props: (route) => ({
      magicToken: route.query.token,
      tokenType: route.query.type,
      nextRoute: route.query.nextRoute,
      errorMessage: route.query.errorMessage,
    }),
    name: 'Login',
    beforeEnter: (_to, _from, next) => {
      const globalStore = useGlobalStore();
      globalStore.resetState();
      next();
    },
  },
  {
    path: '/logout',
    component: LogoutLayout,
    name: 'Logout',
  },
  {
    path: '/link-accounts',
    component: LinkAccounts,
    name: 'LinkAccounts',
  },
  {
    path: '/csat',
    component: CsatView,
    name: 'Csat',
    props: (route) => ({
      request: route.query.request,
      rating: route.query.rating,
    }),
  },
  {
    path: '/eep-manufacturer-csat',
    component: EepManufacturerCsatView,
    name: 'EepManufacturerCsat',
    props: (route) => ({
      eepEscalationId: route.query.escalationId,
      rating: route.query.rating,
    }),
  },
  {
    path: '/vendor-dispatch',
    component: VendorDispatch,
    name: 'VendorDispatch',
    props: (route) => ({
      requestId: route.query.requestId,
      vendorResponse: route.query.vendorResponse,
      token: route.query.token,
      productType: 'FSO',
    }),
  },
  {
    path: '/sso-vendor-dispatch',
    component: SSOVendorDispatch,
    name: 'SSOVendorDispatch',
    props: (route) => ({
      requestId: route.query.requestId,
      vendorResponse: route.query.vendorResponse,
      token: route.query.token,
      productType: 'SSO',
    }),
  },
  {
    path: '/vendor-request-quote',
    component: VendorRequestQuote,
    name: 'VendorRequestQuote',
    props: (route) => ({
      requestId: route.query.requestId,
      vendorResponse: route.query.vendorResponse,
      token: route.query.token,
    }),
  },
  {
    path: '/vendor-follow-up',
    component: VendorFollowUp,
    name: 'VendorFollowUp',
    props: (route) => ({
      requestId: route.query.requestId,
      token: route.query.token,
    }),
  },
  {
    path: '/request-intake-magic-link',
    component: RequestIntakeMagicLink,
    name: 'RequestIntakeMagicLink',
    props: (route) => ({
      token: route.query.token,
    }),
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
  },
  {
    path: '/app',
    name: 'AppDownloadView',
    component: AppDownloadView,
  },
  {
    path: '/maintenance',
    component: MaintenanceView,
    name: 'Maintenance',
  },
  {
    path: '/invalid-link',
    component: InvalidLinkView,
    name: 'InvalidLink',
  },
  {
    path: '/error',
    component: ErrorView,
    name: 'Error',
    props: (route) => ({
      errorCode: route.query.errorMessage,
    }),
  },
  {
    path: '/',
    name: 'Home',
    component: PortalLayout,
    beforeEnter: async (to, from, next) => {
      const authStore = useAuthStore();
      const { isBohUser } = authStore;
      const userRoles = authStore.currentUserRoles;
      const detailPage =
        to?.name === 'RequestDetail' || to?.name === 'AssetDetail';
      if (to?.name !== 'Admin' && isBohUser && !detailPage) {
        next({
          name: 'Admin',
        });
      } else if (
        to?.name !== 'NoPermissions' &&
        !userRoles?.length &&
        !isBohUser
      ) {
        next({
          name: 'NoPermissions',
        });
      } else {
        next();
      }
    },
    redirect: '/home',
    children: [
      {
        path: '/no-permissions',
        component: NoPermissions,
        name: 'NoPermissions',
      },
      {
        path: '/admin',
        component: AdminView,
        name: 'Admin',
      },
      {
        path: '/home',
        component: AtAGlance,
        name: 'AtAGlance',
        meta: { permissions: ['isPartnerOrAbove', 'isFullService'] },
      },
      {
        path: '/requests',
        /**
         * Double slash alias is here to account for a bug in how links
         * were formatted in activity emails. This can be removed anytime after Feb 1st 2022
         * @see https://86repairs.atlassian.net/browse/BOH-2223
         */
        alias: ['//requests'],
        name: 'Requests',
        component: RequestsView,
        props: true,
        children: [
          {
            path: '',
            name: 'RequestPage',
            components: {
              default: RequestPage,
              CreateButton: RequestCreateButton,
            },
          },
          {
            path: '/escalations',
            name: 'EscalationsLayout',
            components: {
              default: EscalationsLayout,
              CreateButton: EscalationCreateButton,
            },
            meta: { permissions: ['hasEepReadPermissions'] },
          },
        ],
      },
      {
        path: '/requests/:id',
        alias: [
          '/requests/:id/detail',
          /**
           * See above
           */
          '//requests/:id/detail',
          '//requests/:id',
        ],
        name: 'RequestDetail',
        component: RequestDetail,
      },
      {
        path: '/requests/:id/vendor-visit',
        name: 'SelfServiceVendorFollowUp',
        component: SelfServiceVendorFollowUp,
      },
      {
        path: '/escalations/:id',
        name: 'EscalationDetail',
        component: EscalationDetail,
      },
      {
        path: '/assets',
        name: 'Assets',
        component: AssetsView,
      },
      {
        path: '/assets/:id',
        name: 'AssetDetail',
        component: AssetDetail,
      },
      {
        path: '/reporting',
        component: ReportingView,
        children: [
          {
            path: '',
            component: RequestReporting,
            meta: { permissions: ['isFullService'] },
          },
          {
            path: 'requests',
            name: 'RequestReporting',
            component: RequestReporting,
            meta: { permissions: ['isFullService'] },
          },
          {
            path: 'invoices',
            component: InvoiceReportingView,
            children: [
              {
                path: '/',
                name: 'InvoiceReporting',
                component: InvoiceReporting,
              },
              {
                path: 'unmatched',
                name: 'UnmatchedInvoices',
                component: UnmatchedInvoices,
              },
            ],
          },
          {
            path: 'insights',
            name: 'InsightsReporting',
            component: InsightsReporting,
          },
          {
            path: 'vendor-insights',
            name: 'VendorInsightsReporting',
            component: VendorInsightsReporting,
          },
          {
            path: 'pm-insights',
            name: 'InsightsReporting',
            component: PMInsightsReporting,
          },
        ],
      },
      {
        path: '/profile',
        name: 'Profile',
        component: ProfileView,
      },
      {
        path: '/vendor',
        name: 'VendorProfile',
        component: VendorProfile,
        meta: { permissions: ['isVendorWithUpdatePermissions'] },
      },
      {
        path: '/vendors',
        name: 'Vendors',
        component: VendorsView,
        redirect: '/vendors/network',
        children: [
          {
            path: 'network',
            name: 'VendorNetworkCard',
            component: VendorNetworkCard,
          },
          {
            path: 'history',
            name: 'VendorHistoryCard',
            component: VendorHistoryCard,
          },
          {
            path: 'eightysix',
            name: 'VendorEightysixNetworkCard',
            component: VendorEightysixNetworkCard,
          },
        ],
      },
      {
        path: '/tasks',
        name: 'TasksLayout',
        component: TasksLayout,
        meta: { permissions: ['isFullService'] },
      },
      {
        path: '/tasks/:id',
        component: TaskDetailLayout,
        meta: { permissions: ['isFullService'] },
        children: [
          {
            path: '',
            name: 'TaskDetail',
            component: TaskDetail,
            beforeEnter: async (to, from, next) => {
              const taskStore = useTaskStore();
              await taskStore.getTaskDetails(to.params.id);
              next();
            },
          },
          {
            path: 'request/:requestId/guides',
            name: 'TaskTroubleshootingGuidesList',
            component: RequestTroubleshootingGuidesList,
          },
          {
            path: 'request/:requestId/guides/:guideId',
            name: 'TaskTroubleshootingGuide',
            component: RequestTroubleshootingGuide,
          },
        ],
      },
      {
        path: '/requests/:requestId/guides',
        name: 'RequestTroubleshootingGuidesList',
        component: RequestTroubleshootingGuidesList,
      },
      {
        path: '/requests/:requestId/guides/:guideId',
        name: 'RequestTroubleshootingGuide',
        component: RequestTroubleshootingGuide,
      },
    ],
  },
  {
    path: '/s3',
    name: 'S3',
    component: {
      template: '<div>S3</div>',
    },
    beforeEnter: async (to, from, next) => {
      const url = await getSignedUrl(to.query.url, esApiV3);
      window.location.href = url;
      next();
    },
  },
  {
    path: '/qr/:id',
    name: 'AssetQRCode',
    component: {
      template: '<div></div>',
    },
    beforeEnter: async (to, _from, next) => {
      // if (!authService.hasAuthToken()) {
      next({ name: 'AppDownloadView' });
      // } else {
      //   const assetId = await getAssetIdByQrCodeId(to.params.id);
      //   next(assetId ? { name: 'AssetDetail', params: { id: assetId } } : '/');
      // }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

const DEFAULT_INTERCOM_ROLE_ATTRIBUTES = {
  'Role - Owner': false,
  'Role - Partner': false,
  'Role - Director of Operations': false,
  'Role - Director of Facilities': false,
  'Role - Area Supervisor - Operations': false,
  'Role - Area Supervisor - Facilities': false,
  'Role - Internal Technician': false,
  'Role - General Manager': false,
  'Role - Manager': false,
  'Role - Assistant Manager': false,
  'Role - Kitchen Manager': false,
  'Role - Store Staff': false,
  'Role - Chief Financial Officer': false,
  'Role - Director of Finance': false,
  'Role - Controller': false,
  'Role - Accountant': false,
  'Role - Accounts Payable': false,
  'Role - Dispatcher': false,
  'Role - Technician': false,
  'Role - Billing': false,
  'Role - Other': false,
  'Role - Service Manager': false,
  'Role - District Manager': false,
  'Role - Corporate Staff': false,
};
async function populateUser() {
  const authStore = useAuthStore();
  if (!authStore.hasUserInfo) {
    await authStore.getUserInfo();

    const user = authStore.currentUser;

    if (!userProfileService.isImpersonatingUser()) {
      const userRoleAttributes = user.sspRoles
        .map((r) => r.sspRole)
        .reduce(
          (acc, role) => {
            acc[`Role - ${role}`] = true;
            return acc;
          },
          { ...DEFAULT_INTERCOM_ROLE_ATTRIBUTES },
        );

      const isPartstown = user?.customerSettings?.some(
        (cs) => cs.customer?.partnership === 'Parts Town',
      );

      window.Intercom('boot', {
        app_id: window.intercomAppId,
        user_id: user.id,
        name: user.name,
        email: user.email,
        phone: user.phone,
        user_hash: user.intercomHmac,
        'Is Partstown': isPartstown,
        ...userRoleAttributes,
      });
      analyticsService.setUserProperties({
        Name: user.name,
        Roles: user.sspRoles?.map((r) => r.sspRole) ?? [],
        isSelfService: user.customerSetting?.customer?.isSelfService,
        isEnterprise: user.customerSetting?.customer?.enterprise,
        isPartstown: isPartstown ?? false,
      });
    }

    const customerStore = useCustomerStore();

    await customerStore.getCustomer().then(() => {
      const { customer } = customerStore;
      if (customer?.id) {
        analyticsService.setUserCustomer(customer.id, customer.name);
        const isPartstown = customer?.partnership === 'Parts Town';
        window.Intercom('update', {
          isSelfService: customer?.isSelfService ?? false,
          isEnterprise: customer?.enterprise ?? false,
          'Is Partstown': isPartstown ?? false,
          hide_default_launcher: user?.isBohUser,
        });
        analyticsService.setUserProperties({
          isSelfService: customer.isSelfService,
          isEnterprise: customer?.enterprise ?? false,
          isPartstown: isPartstown ?? false,
        });
      }
    });

    const locationStore = useLocationStore();

    locationStore.getLocations().then(() => {
      const { locations } = locationStore;
      if (locations?.length) {
        analyticsService.setUserLocations(locations.map((l) => l.displayName));
      }
    });

    const vendorId = authStore.currentUserVendorId;
    if (vendorId != null) {
      getVendorForUser(false).then((vendor) => {
        if (vendor?.id) {
          analyticsService.setUserVendor(vendor.id, vendor.name);
        }
      });
    }
  }
}

async function loadUserCaslRules() {
  const authStore = useAuthStore();
  await authStore.loadUserCaslRules();
}

function insufficientPermissionsForRoute(to) {
  const authStore = useAuthStore();
  const customerStore = useCustomerStore();
  const routeRequiresFullService = to.matched.some((record) =>
    record.meta.permissions?.includes('isFullService'),
  );
  const routeRequiresPartnerOrAbove = to.matched.some((record) =>
    record.meta.permissions?.includes('isPartnerOrAbove'),
  );
  const routeRequiresVendorUpdatePermissions = to.matched.some((record) =>
    record.meta.permissions?.includes('isVendorWithUpdatePermissions'),
  );
  const routeRequiresEepReadPermissions = to.matched.some((record) =>
    record.meta.permissions?.includes('hasEepReadPermissions'),
  );

  let insufficientPermissions = false;
  if (routeRequiresFullService && routeRequiresPartnerOrAbove) {
    insufficientPermissions = !authStore.canViewDashboard;
  } else if (routeRequiresFullService) {
    insufficientPermissions = customerStore.isSelfServiceCustomer;
  } else if (routeRequiresPartnerOrAbove) {
    insufficientPermissions =
      !authStore.gmAndAboveUser && !authStore.isInternalTechUser;
  } else if (routeRequiresVendorUpdatePermissions) {
    insufficientPermissions = !authStore.isVendorWithUpdatePermissions;
  } else if (routeRequiresEepReadPermissions) {
    insufficientPermissions = !authStore.isUserWhoCanReadEeps;
  }
  return insufficientPermissions;
}

router.beforeEach(async (to, from, next) => {
  await autoRefreshService.handleNewVersionAvailable();
  analyticsService.logEvent('Page View', {
    Page: to.name,
    Authenticated: authService.hasAuthToken(),
    'Previous Url': from.fullPath,
    'Email Link Source': to.query?.utm_campaign,
  });

  if (to.name === 'Login') {
    next();
    return;
  }

  if (!authService.hasAuthToken()) {
    if (ALLOW_AUTH_SKIP.includes(to.name)) {
      next();
      return;
    }
    next({
      name: 'Login',
      query: { nextRoute: new URL(window.location.href).toString() },
    });
    return;
  }
  await populateUser();

  if (insufficientPermissionsForRoute(to)) {
    next('/requests');
    return;
  }

  if (!userProfileService.isImpersonatingUser()) {
    window.Intercom('update');
  }

  if (!to.path.startsWith('/requests')) {
    const requestStore = useRequestStore();
    requestStore.clearFilter();
    requestStore.setPagination(null);
  }
  if (!to.path.startsWith('/assets')) {
    const assetStore = useAssetStore();
    assetStore.clearFilter();
  }

  await loadUserCaslRules();

  next();
});

export default router;
